import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {
        arabic: "من حسن إسلام المرء تركه ما لا يعنيه",
        english: "It is from the excellence of a man's Islam that he should shun that which is of no concern to him.",
        source: "Riyad as-Salihin 67"
    }
}

const hadith = createSlice({
    name: "hadith",
    initialState,
    reducers: {
        setHadith: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const {setHadith} = hadith.actions;
export default hadith.reducer;
