import styles from './MaintenanceMode.module.css';

const MaintenanceMode = () => {
  return (
    <div>
      <div className={styles.maintenanceMode}></div>
      <div className={styles.overlay}>
        <h1 className={styles.title}>Website Under Maintenance</h1>
        <p className={styles.message}>Sorry, This site is still under Construction. Please check back soon.</p>
      </div>
    </div>
  );
};

export default MaintenanceMode;