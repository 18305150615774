import { useEffect } from "react";
import { Outlet, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import IslamiyyaRegistration from "./pages/IslamiyyaRegistration";
import Programs from "./pages/Programs";
import Navbar from "./components/Navbar/Navbar";
import MaintenanceMode from "./components/MaintenanceMode/MaintenanceMode";
import SignUp from "./pages/SignUp";
import LogIn from "./pages/LogIn";
import ForgotPassword from "./pages/ForgotPassword";
import { auth } from "./firebase/config";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setLoggedOut } from "./redux/slices/newUser";


export default function App() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { loggedIn, value: user } = useSelector(state => state.newUser);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser === 1) {
        try {
          const idTokenResult = await currentUser.getIdTokenResult();

          switch (idTokenResult.claims.role) {
            case 'ADMIN':
              // Show admin UI
              console.log(idTokenResult);

              dispatch(setLoggedIn());

              break;
            case 'USER':
              // Show user UI
              console.log(idTokenResult);

              break;
            case 'GOOGLE':
              // Show UI
              console.log(idTokenResult);

              break;
            case 'GOOGLE_USER':
              // Show UI
              console.log(idTokenResult);

              break;
            case 'STUDENT':
              // Show UI
              console.log(idTokenResult);

              break;

            default:
              // Assume regular user UI

              const providers = currentUser.providerData;
              const provider = providers[0]?.providerId;

              if ((user.signInStage === 2 || user.signInStage === 1) && provider === 'google.com') {
                const queryParams = new URLSearchParams({ signup: 'unfinished' });
                navigate(`/sign-up?${queryParams.toString()}`);
              } else {
                console.log(currentUser)
                dispatch(setLoggedIn());
              }
              break;
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          dispatch(setLoggedOut()); // Dispatch logout on error
        }
      } else {
        dispatch(setLoggedOut());
      }

      // Clean up listener on unmount
      return () => unsubscribe();
    });

    return unsubscribe; // Return the unsubscribe function
  }, [user.signInStage]); // eslint-disable-line react-hooks/exhaustive-deps

  const WithNav = () => {
    return (
      <>
        <Navbar loggedIn={loggedIn} />
        <Outlet />
      </>
    );
  }
  const WithoutNav = () => {
    return (
      <Outlet />
    );
  }

  return (
    true? <MaintenanceMode /> :
    <div>
      {loggedIn ?
        <Routes>
          <Route element={<WithoutNav />}>
            {/* Redirect to home if already logged in */}
            <Route path="/sign-up" element={<Navigate to="/" />} />
            <Route path="/login" element={<Navigate to="/" />} />
            <Route path="/forgot-password" element={<Navigate to="/" />} />
          </Route>
          <Route element={<WithNav />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/islamiyya" element={<IslamiyyaRegistration />} />
            <Route path="/programs" element={<Programs />} />
          </Route>
        </Routes>
        :
        <Routes>
          <Route element={<WithoutNav />}>
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route element={<WithNav />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/islamiyya" element={<IslamiyyaRegistration />} />
            <Route path="/programs" element={<Programs />} />
          </Route>
        </Routes>
      }
    </div>
  );
};
