import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {
        fullName: "",
        email: "",
        uid: "",
        photoURL: "./images/dummy-client/1.png",
        schoolId: "",
        dept: "",
        level: "",
        rememberMe: true,
        provider: "email",
        signInStage: 1,
        userRole: 'ANONYMOUS'
    },
    loggedIn: false
};

const newUser = createSlice({
    name: "newUser",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.value = action.payload;
        },
        setLoggedIn: (state) => {
            state.loggedIn = true;
        },
        setLoggedOut: (state) => {
            state.loggedIn = false;
        }
    }
});

export const {setUser, setLoggedIn, setLoggedOut} = newUser.actions;
export default newUser.reducer;
