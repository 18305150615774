let depts = [
    "Information and Communication Engineering",
    "Chemical Engineering",
    "Civil Engineering",
    "Computer Engineering",
    "Electrical & Electronics Engineering",
    "Mechanical Engineering",
    "Mechatronics Engineering",
    "Petroleum & Gas Engineering",
    "Accounting",
    "Architecture",
    "Banking and Finance",
    "Biochemistry",
    "Biology",
    "Biotechnology",
    "Building",
    "Business Administration",
    "Computer Science",
    "Criminology & Security Studies",
    "Cyber Security",
    "Data Science",
    "Economics",
    "Entrepreneurship",
    "Estate Management",
    "Human Anatomy",
    "Human Physiology",
    "Industrial Chemistry",
    "Information System",
    "Information Technology",
    "Marketing",
    "Mass Communication",
    "Microbiology",
    "Peace Studies and Conflict Resolution",
    "Political Science & International Relations",
    "Psychology",
    "Public Administration",
    "Public Health",
    "Quantity Surveying",
    "Sociology",
    "Software Engineering",
    "Urban and Regional Planning",
    "Law",
    "Public Health",
    "Medicine & Surgery"
];
depts.sort();

export default depts;
