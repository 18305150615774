import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {
        surah: "At-Tahrim",
        verse: "6",
        arabic: "يَـٰٓأَيُّهَا ٱلَّذِينَ ءَامَنُواْ قُوٓاْ أَنفُسَكُمۡ وَأَهۡلِيكُمۡ نَارٗا وَقُودُهَا ٱلنَّاسُ وَٱلۡحِجَارَةُ عَلَيۡهَا مَلَـٰٓئِكَةٌ غِلَاظٞ شِدَادٞ لَّا يَعۡصُونَ ٱللَّهَ مَآ أَمَرَهُمۡ وَيَفۡعَلُونَ مَا يُؤۡمَرُونَ ٦",
        translation: "O believers! Protect yourselves and your families from a Fire whose fuel is people and stones, overseen by formidable and severe angels, who never disobey whatever Allah orders—always doing as commanded."
    }
}

const verse = createSlice({
    name: "verse",
    initialState,
    reducers: {
        setVerse: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const {setVerse} = verse.actions;
export default verse.reducer;
