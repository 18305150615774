// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

// Firebase config object - Currently using 'attahir.nasir@gmail.com'
const firebaseConfig = {
  apiKey: "AIzaSyDQKDMC4nVbz1wXkzSGKu2b-9UIqRgwvDg",
  authDomain: "test-project-7ece4.firebaseapp.com",
  projectId: "test-project-7ece4",
  storageBucket: "test-project-7ece4.appspot.com",
  messagingSenderId: "668704119962",
  appId: "1:668704119962:web:179bbcbee84b32b5abd323",
  measurementId: "G-GL78BC6Y02"
};

// Initialize Firebase
const confg = initializeApp(firebaseConfig);

// init services
export const analytics = getAnalytics(confg);
export const db = getFirestore(confg);
export const auth = getAuth(confg);
export const storage = getStorage(confg);
export const functions = getFunctions(confg);
export const googleProvider = new GoogleAuthProvider();