import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {
        fajr: "05:22",
        dhuhr: "12:53",
        asr: "16:18",
        maghrib: "19:06",
        isha: "20:17"
    }
}

const prayerTimes = createSlice({
    name: "prayerTimes",
    initialState,
    reducers: {
        setTimes: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const {setTimes} = prayerTimes.actions;
export default prayerTimes.reducer;
